<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import service from '../api/modalInformationService'
import {
  CargillCrudMetaView,
  responseToOptions,
  helpers
} from '@cargill/shared'
import _ from 'lodash'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      createValidationSchema: Function
    }
  },
  created() {
    const getMeta = async () => {
      const meta = await service.getMeta()
      const ctx = await service.getValidationContext()
      ctx.modalTypeOptions = responseToOptions(ctx.modalTypeOptions)
      ctx.modalOptions = responseToOptions(ctx.modalOptions)
      ctx.unityOptions = responseToOptions(ctx.unityOptions)
      const modalOptionsById = _.keyBy(ctx.modalOptions, (x) => x.value)

      _.forIn(ctx.modalsByModalType, (modals, modalType) => {
        ctx.modalsByModalType[modalType] = modals.map(
          (modal) => modalOptionsById[modal]
        )
      })

      const modalField = meta.fields.find((field) => field.id == 'modal')
      const modalTypeField = meta.fields.find(
        (field) => field.id == 'modalType'
      )

      const capacityUnity = meta.fields.find(
        (field) => field.id == 'capacityUnity'
      )
      capacityUnity.options = ctx.unityOptions
      modalTypeField.options = ctx.modalTypeOptions
      modalField.options = ctx.modalOptions

      const getModalOptions = (value) =>
        value?.value == null
          ? ctx.modalOptions
          : ctx.modalsByModalType[value.value]

      modalTypeField.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          modalField,
          fields.modal,
          getModalOptions(changedValue)
        )
      }

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)

        const requiredMappings = {
          capacityUnity: 'capacity'
        }
        helpers.includeImpactedRequired(
          requiredMappings,
          validationSchema,
          translate
        )
        return yup.object().shape(validationSchema)
      }

      return meta
    }
    getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
